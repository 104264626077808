<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ $t("manage_student") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-dialog v-model="dialog" max-width="1000px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #d6d0fc"
                      >
                        <span style="font-size: 1em; color: #3316f2">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_student")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title v-if="this.stuId != ''">{{
                        $t("edit_student")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_student")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                          resetValidation();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 600px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col sm="3" cols="12">
                                <label class="label required">{{
                                  $t("student_id")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="student_id"
                                  placeholder="e.g: B20190032"
                                  outlined
                                  :rules="[rules.required]"
                                ></v-text-field>
                                <label class="label required">{{
                                  $t("date_of_birth")
                                }}</label>
                                <v-dialog
                                  ref="dialog"
                                  v-model="menuJDate"
                                  :return-value.sync="date_of_birth"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      class="my-3"
                                      outlined
                                      placeholder="e.g 2021-01-01"
                                      v-model="date_of_birth"
                                      append-icon="event"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[rules.required]"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date_of_birth"
                                    scrollable
                                    :max="maxDateOfBirth"
                                    :min="minDateOfBirth"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuJDate = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(date_of_birth)"
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                              <v-col sm="3" cols="12">
                                <label class="label required">{{
                                  $t("first_name_en")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="first_name_en"
                                  precision="3"
                                  placeholder="e.g: ចន ស្មីត"
                                  outlined
                                  :rules="[rules.required]"
                                />
                                <label class="label required">{{
                                  $t("first_kh_name")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="first_name_kh"
                                  precision="3"
                                  placeholder="e.g: ចន ស្មីត"
                                  outlined
                                  :rules="[rules.required]"
                                />
                              </v-col>
                              <v-col sm="3" cols="12">
                                <label class="label required">{{
                                  $t("last_name_en")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="last_name_en"
                                  precision="3"
                                  placeholder="e.g: Jonh Smith"
                                  outlined
                                  :rules="[rules.required]"
                                ></v-text-field>
                                <label class="label required">{{
                                  $t("last_kh_name")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="last_name_kh"
                                  precision="3"
                                  placeholder="e.g: Jonh Smith"
                                  outlined
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col sm="3" cols="12">
                                <label class="label required">{{
                                  $t("gender")
                                }}</label>
                                <v-select
                                  class="my-3"
                                  v-model="gender"
                                  single-line
                                  :items="ListGender"
                                  label="All"
                                  outlined
                                  :rules="[rules.required]"
                                ></v-select>
                                <span v-if="this.stuId">
                                  <label class="label required">{{
                                    $t("status")
                                  }}</label>
                                  <div class="mb-3"></div>
                                  <v-select
                                    v-model="status"
                                    single-line
                                    :items="ListActive"
                                    label="All"
                                    outlined
                                    hide-details
                                    :rules="[rules.required]"
                                  >
                                  </v-select>
                                </span>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col sm="4" cols="12">
                                <v-card>
                                  <v-card-title class="text-h5">
                                    Contact Information
                                  </v-card-title>
                                  <v-list class="ml-3 mr-3">
                                    <label class="label required">{{
                                      $t("email")
                                    }}</label>
                                    <v-text-field
                                      v-model="email"
                                      precision="3"
                                      placeholder="name@gmail.com"
                                      outlined
                                      :rules="[rules.email]"
                                      :readonly="this.stuId != ''"
                                    ></v-text-field>
                                    <label class="label">{{
                                      $t("phone_numbers")
                                    }}</label>
                                    <span>
                                      <v-text-field
                                        v-model="phone_numbers0"
                                        type="number"
                                        placeholder="e.g: 011223344"
                                        outlined
                                      />
                                    </span>
                                    <span>
                                      <v-text-field
                                        type="number"
                                        v-model="phone_numbers1"
                                        placeholder="e.g: 022556677"
                                        outlined
                                      />
                                    </span>
                                  </v-list>
                                </v-card>
                              </v-col>
                              <v-col sm="8" cols="12">
                                <v-card>
                                  <v-card-title class="text-h5">
                                    Student Information
                                  </v-card-title>
                                  <v-row dense>
                                    <v-col sm="5" cols="12" class="ml-10 mr-3">
                                      <label class="label">{{
                                        $t("foundation_id")
                                      }}</label>

                                      <v-select
                                        v-model="major1_id.id"
                                        single-line
                                        item-text="name_en"
                                        :items="listMajorsFoundation"
                                        v-on:input="
                                          changeRouteMajor1(`${major1_id}`)
                                        "
                                        item-value="id"
                                        label="All"
                                        outlined
                                      >
                                      </v-select>

                                      <label
                                        class="label"
                                        v-show="this.role != 'Foundation'"
                                        >{{ $t("major_id") }}</label
                                      >

                                      <v-select
                                        v-model="major2_id.id"
                                        single-line
                                        v-on:input="
                                          changeRouteMajor2(`${major2_id}`)
                                        "
                                        :items="listMajors"
                                        item-text="name_en"
                                        item-value="id"
                                        v-show="this.role != 'Foundation'"
                                        label="All"
                                        outlined
                                      >
                                      </v-select>

                                      <label class="label">{{
                                        $t("exam_id")
                                      }}</label>
                                      <v-text-field
                                        v-model="exam_id"
                                        precision="3"
                                        placeholder="e.g: quiz001"
                                        outlined
                                      />
                                    </v-col>

                                    <v-col sm="5" cols="12" class="ml-8 mr-3">
                                      <label class="label">{{
                                        $t("foundation_generation_id")
                                      }}</label>

                                      <v-select
                                        v-model="major_generation1_id"
                                        single-line
                                        :items="listMajorGen1"
                                        item-text="name_en"
                                        item-value="id"
                                        label="All"
                                        outlined
                                      >
                                      </v-select>
                                      <label
                                        class="label"
                                        v-show="this.role != 'Foundation'"
                                        >{{ $t("major_generation_id") }}</label
                                      >

                                      <v-select
                                        v-model="major_generation2_id"
                                        single-line
                                        :items="listMajorGen2"
                                        item-text="name_en"
                                        item-value="id"
                                        label="All"
                                        v-show="this.role != 'Foundation'"
                                        outlined
                                      >
                                      </v-select>
                                      <label class="label">{{
                                        $t("group_name")
                                      }}</label>
                                      <v-text-field
                                        v-model="group_name"
                                        precision="3"
                                        placeholder="e.g: CS2B"
                                        outlined
                                      />
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          :disabled="!valid"
                          @click.prevent="onSaveClose(), validate()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog1" max-width="550px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        style="height: 40px"
                        class="
                          text-capitalize
                          green
                          rounded-pill
                          text-white
                          float-right
                          mr-5
                        "
                        type="primary"
                      >
                        <v-icon class="mr-2">mdi-upload</v-icon>
                        {{ $t("import_file") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>{{ $t("import_student") }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="(dialog1 = false), clear(), resetValidation()"
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 250px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col sm="6" cols="12" class>
                                <label class="label">{{
                                  $t("foundation_id")
                                }}</label>

                                <v-select
                                  v-model="major1_id"
                                  single-line
                                  :items="listMajorsFoundation"
                                  item-text="name_en"
                                  v-on:input="changeRouteMajor1(`${major1_id}`)"
                                  item-value="id"
                                  label="All"
                                  outlined
                                >
                                </v-select>

                                <label
                                  class="label"
                                  v-show="this.role != 'Foundation'"
                                  >{{ $t("major_id") }}</label
                                >

                                <v-select
                                  v-model="major2_id"
                                  single-line
                                  v-on:input="changeRouteMajor2(`${major2_id}`)"
                                  :items="listMajors"
                                  v-show="this.role != 'Foundation'"
                                  item-text="name_en"
                                  item-value="id"
                                  label="All"
                                  outlined
                                >
                                </v-select>
                              </v-col>
                              <v-col sm="6" cols="12" class>
                                <label class="label">{{
                                  $t("foundation_generation_id")
                                }}</label>

                                <v-select
                                  v-model="major_generation1_id"
                                  single-line
                                  :items="listMajorGen1"
                                  item-text="name_en"
                                  item-value="id"
                                  label="All"
                                  outlined
                                >
                                </v-select>
                                <label
                                  class="label"
                                  v-show="this.role != 'Foundation'"
                                  >{{ $t("major_generation_id") }}</label
                                >

                                <v-select
                                  v-model="major_generation2_id"
                                  single-line
                                  :items="listMajorGen2"
                                  item-text="name_en"
                                  v-show="this.role != 'Foundation'"
                                  item-value="id"
                                  label="All"
                                  outlined
                                >
                                </v-select>
                              </v-col>
                              <v-col
                                sm="12"
                                cols="12"
                                style="margin-top: -40px"
                              >
                                <v-file-input
                                  show-size
                                  class="required"
                                  :rules="rulesFile"
                                  v-model="filecsv"
                                  accept=".csv"
                                  placeholder="Click here to select your file"
                                  label="Input File Here"
                                  type="file"
                                  :clearable="false"
                                  @change="handleFileUpload"
                                ></v-file-input>
                              </v-col>
                              <!-- <input
                                class="ml-5"
                                type="file"
                                accept=".csv"
                                @change="handleFileUpload($event)"
                              /> -->
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions class="function_footer">
                        <v-btn
                          class="btn_cancel float-left"
                          @click="(dialog1 = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          class="green text-white float-right"
                          :disabled="!valid"
                          @click.prevent="importStudentCSV(), validate()"
                          >{{ $t("submit") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    class="mx-4 float-right white--text rounded-pill"
                    color="blue"
                    target="_blank"
                    :href="this.csvTemplate"
                    ><v-icon left> mdi-file-document </v-icon>
                    {{ $t("download_template") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listStudent"
                        :search="search"
                        :sort-by="['name_en', 'name_kh']"
                        :sort-desc="[false, true]"
                        multi-sort
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("student_id") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("email") }}</th>
                              <th>{{ $t("gender") }}</th>
                              <th>{{ $t("phone_numbers") }}</th>
                              <th>{{ $t("date_of_birth") }}</th>
                              <th>{{ $t("group_name") }}</th>
                              <th>{{ $t("status") }}</th>
                              <th>{{ $t("foundation_id") }}</th>
                              <th>{{ $t("foundation_generation_id") }}</th>
                              <th>{{ $t("major_id") }}</th>
                              <th>{{ $t("major_generation_id") }}</th>

                              <th class="text-center">{{ $t("Action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody
                            style="white-space: nowrap"
                            v-if="items.length > 0"
                          >
                            <tr
                              v-for="(stu, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ stu.student_id }}</td>
                              <td class="text-left">{{ stu.name_en }}</td>
                              <td class="text-left">{{ stu.name_kh }}</td>
                              <td class="text-left">{{ stu.email }}</td>
                              <td>
                                <v-chip
                                  :color="getGenderColor(stu.gender)"
                                  dark
                                >
                                  <v-icon left>
                                    mdi-account-circle-outline
                                  </v-icon>
                                  {{ stu.gender }}
                                </v-chip>
                              </td>
                              <td class="text-center">
                                <span
                                  v-for="(phone, index) in stu.phone_numbers"
                                  :key="index"
                                >
                                  <v-chip
                                    class="ma-2"
                                    color="success"
                                    outlined
                                    v-if="phone"
                                  >
                                    <v-icon left> mdi-phone </v-icon>
                                    {{ phone }}
                                  </v-chip>
                                </span>
                              </td>
                              <td>{{ stu.date_of_birth }}</td>
                              <td>{{ stu.group_name }}</td>
                              <td>
                                <v-chip :color="getStatus(stu.status)" dark>
                                  {{ stu.status }}
                                </v-chip>
                              </td>
                              <td>{{ stu.major1 }}</td>
                              <td>{{ stu.major_gen1 }}</td>
                              <td>{{ stu.major2 }}</td>
                              <td>{{ stu.major_gen2 }}</td>

                              <td class="text-center">
                                <v-btn
                                  @click="onEditItem(stu)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="primary"
                                >
                                  <v-icon size="15" dark>fa fa-pen</v-icon>
                                </v-btn>
                                <v-btn
                                  @click="deleteitem(stu)"
                                  class="mx-2"
                                  fab
                                  color="error"
                                  small
                                >
                                  <v-icon size="15" dark>fa fa-trash</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                :colspan="headers.length + 2"
                                style="text-align: center"
                              >
                                <v-alert dense outlined type="error">
                                  No data available in table
                                </v-alert>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import store from "@/store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  createStudent,
  getStudent,
  updateStudent,
  deleteStudent,
  getMajor,
  getMajorGenerationById,
  importStudentCSV,
} from "@schoolbase/web-client-lib";
import {
  GENDER,
  ACTIVE,
  STUDENT_TEMPLATE_CSV_FROM_FIREBASE,
} from "@schoolbase/core";
import Papa from "papaparse";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      file: "",
      filecsv: [],
      parsed: false,
      search: "",
      last_name_en: "",
      last_name_kh: "",
      first_name_en: "",
      first_name_kh: "",
      isLoading: false,
      myLoading: false,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      token: store.getters.getToken,
      menuJDate: false,
      csvTemplate: STUDENT_TEMPLATE_CSV_FROM_FIREBASE,
      ListGender: GENDER,
      ListActive: ACTIVE,
      rules: {
        required: (v) => !!v || "This field is required",
        email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      },
      rulesFile: [
        (v) => !!v || "File is required",
        (v) => (v && v.size > 0) || "File is required",
      ],
      form: false,
      valid: true,
      date_of_birth: new Date(
        new Date().getFullYear() - 20,
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10),
      minDateOfBirth: new Date(
        new Date().getFullYear() - 50,
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10),
      maxDateOfBirth: new Date(
        new Date().getFullYear() - 10,
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substr(0, 10),
      headers: [
        { text: "No", value: "no", sortable: false },
        { text: "Student ID", value: "student_id" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Email", value: "email" },
        { text: "Gender", value: "gender" },
        { text: "Foundation ID", value: "major1_id" },
        { text: "Foundation Generation ID", value: "major_generation1_id" },
        { text: "Major Generation ID", value: "major_generation2_id" },
        { text: "Date Of Birth", value: "date_of_birth" },
        { text: "Major ID", value: "major2_id" },
        { text: "Group Name", value: "group_name" },
        { text: "Exam ID", value: "exam_id" },
        { text: "Phone Number", value: "phone_numbers" },
        { text: "Status", value: "status" },
      ],
      listStudent: [],
      listMajors: [],
      listMajorsFoundation: [],
      listMajorGen1: [],
      listMajorGen2: [],
      dialog: false,
      dialog1: false,
      major2_id: {},
      unparsedResults: null,
      major1_id: {},
      group_name: "",
      loggedMajor: store.getters.getLoggedMajor,
      gender: "",
      major_generation2_id: "",
      phone_numbers: "",
      phone_numbers1: "",
      phone_numbers0: "",
      exam_id: "",
      status: "",
      stuId: "",
      name_en: "",
      role: "",
      name_kh: "",
      email: "",
      student_id: "",
      major_generation1_id: "",
      obj_student: {},
      userId: "",
      currentFile: undefined,
      progress: 0,
    };
  },

  methods: {
    handleFileUpload(event) {
      this.file = event;
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.students = results.data;
          window.console.log(this.students);
          this.parsed = true;
          Object.assign(
            this.obj_student,
            { major1_id: this.major1_id.id },
            { major2_id: this.major2_id.id },
            { major_generation1_id: this.major_generation1_id },
            { major_generation2_id: this.major_generation2_id },
            { students: this.students }
          );
        }.bind(this),
      });
    },
    onEditItem(item) {
      this.student_id = item.student_id;
      this.stuId = item.id;
      this.email = item.email;
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.gender = item.gender;
      this.major2_id.id = item.major2_id;
      this.major_generation1_id = item.major_generation1_id;
      this.major_generation2_id = item.major_generation2_id;
      this.date_of_birth = item.date_of_birth;
      this.major1_id.id = item.major1_id;
      this.group_name = item.group_name;
      this.exam_id = item.exam_id;
      this.status = item.status;
      this.first_name_kh = item.first_name_kh;
      this.first_name_en = item.first_name_en;
      this.last_name_en = item.last_name_en;
      this.last_name_kh = item.last_name_kh;
      this.phone_numbers0 = item.phone_numbers[0];
      this.phone_numbers1 = item.phone_numbers[1];
      this.dialog = true;
    },
    async importStudentCSV() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await importStudentCSV(this.obj_student);
        this.snackbar = {
          message: response.message,
          color: "success",
          show: true,
        };
        this.loadStudent();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
      this.isLoading = false;
      this.myLoading = false;
      this.resetValidation();
      this.clear();
      this.dialog1 = false;
    },
    getGenderColor(gender) {
      if (gender === "Male") {
        return "primary";
      } else {
        return "pink";
      }
    },
    getStatus(status) {
      if (status === "active") {
        return "green";
      } else {
        return "red";
      }
    },

    //load User Function
    async loadStudent() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await getStudent(this.token);
        const filterStudentByMajor = response.payload;
        const roleMajor = this.loggedMajor.payload.name_en;
        this.role = roleMajor;
        const data = filterStudentByMajor.filter(
          (filterStudentByMajor) => filterStudentByMajor.major2 == roleMajor
        );

        this.listStudent = data.sort(function (a, b) {
          var nameA = a.last_name_en;
          var nameB = b.last_name_en;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadMajor() {
      try {
        const APIResponse = await getMajor(this.token);
        const roleMajor = this.loggedMajor.payload.name_en;
        const response = APIResponse.payload;

        this.listMajors = response.filter(
          (response) => response.name_en == roleMajor
        );

        this.listMajorsFoundation = APIResponse.payload.filter(
          (item) => item.name_en == "Foundation"
        );

        this.major1_id = {
          name_en: this.listMajorsFoundation[0].name_en,
          id: this.listMajorsFoundation[0].id,
        };

        this.major2_id = {
          name_en: this.listMajors[0].name_en,
          id: this.listMajors[0].id,
        };
        this.changeRouteMajor1(this.major1_id.id);
        this.changeRouteMajor2(this.major2_id.id);
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadMajorGen1(id) {
      try {
        const APIResponse = await getMajorGenerationById(id, this.token);
        this.listMajorGen1 = APIResponse.payload.sort(function (a, b) {
          var c = new Date(a.start_year);
          var d = new Date(b.start_year);
          return c - d;
        });
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadMajorGen2(id) {
      try {
        const APIResponse = await getMajorGenerationById(id, this.token);
        this.listMajorGen2 = APIResponse.payload.sort(function (a, b) {
          var c = new Date(a.start_year);
          var d = new Date(b.start_year);
          return c - d;
        });
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },

    //Create User Function
    async onSaveClose() {
      const data = {
        student_id: this.student_id,
        last_name_en: this.last_name_en,
        first_name_en: this.first_name_en,
        last_name_kh: this.last_name_kh,
        first_name_kh: this.first_name_kh,
        name_kh: this.name_kh,
        email: this.email,
        gender: this.gender,
        major1_id: this.major1_id.id,
        major_generation1_id: this.major_generation1_id,
        major_generation2_id: this.major_generation2_id,
        date_of_birth: this.date_of_birth,
        major2_id: this.major2_id.id,
        group_name: this.group_name,
        exam_id: this.exam_id,
        status: this.status,
        phone_numbers: [this.phone_numbers0, this.phone_numbers1],
      };
      if (this.stuId !== "") {
        try {
          await updateStudent(this.stuId, data);
          this.loadStudent();
          this.snackbar = {
            message: "Successfully Update Student",
            color: "success",
            show: true,
          };
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      } else {
        try {
          await createStudent(data);
          this.loadStudent();
          this.snackbar = {
            message: "Successfully Create Student",
            color: "success",
            show: true,
          };
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.resetValidation();
      this.clear();
      this.dialog = false;
    },
    clear() {
      this.student_id = "";
      this.stuId = "";
      this.name_en = "";
      this.name_kh = "";
      this.email = "";
      (this.filename = null), (this.gender = "");
      this.first_name_kh = "";
      this.last_name_kh = "";
      this.filecsv = null;
      this.first_name_en = "";
      this.last_name_en = "";
      this.major_generation1_id = "";
      this.date_of_birth = "";
      this.major_generation2_id = "";
      this.phone_numbers0 = "";
      this.group_name = "";
      this.exam_id = "";
      this.status = "";
      this.phone_numbers = "";
      this.phone_numbers1 = "";
    },
    async deleteitem(stu) {
      const index = this.listStudent.indexOf(stu);
      this.deletItems = stu;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listStudent.splice(index, 1);
        await this.deleteItem();
      }
    },
    changeRouteMajor2(a) {
      this.loadMajorGen2(a);
    },
    changeRouteMajor1(b) {
      this.loadMajorGen1(b);
    },

    async deleteItem() {
      try {
        await deleteStudent(this.deletItems.id);
        this.snackbar = {
          message: "Delete Student Successfully",
          color: "success",
          show: true,
        };
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
  },

  async mounted() {
    // this.loadMajorGen();
    this.loadStudent();
    this.loadMajor();
  },
  components: {
    Loading,
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 14px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}
.btn_create_new {
  color: #3316f2 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}
.required:after {
  content: " *";
  color: red;
}

@media (max-width: 576px) {
}
</style>
